import { ref } from '@vue/composition-api'
import contentGeneralRepository from '@/repository/contentGeneralRepository'
import ability from '@/libs/acl/ability'
import store from '@/store'

export default function contentGeneralView() {
  const blankData = {
    xmlId: null,
    active: 1,
    sort: null,
    title: null,
    alias: null,
    detailText: null,
    searchableContent: null,
    showCounter: 0,
    showCounterStart: null,
    metaTitle: null,
    metaKeywords: null,
    metaDescription: null,
    createdUser: '',
    updatedUser: '',
    createdAt: null,
    updatedAt: null,
  }

  const contentData = ref({})

  const getElement = id => new Promise(resolve => {
    contentGeneralRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Статьи с текущем идентификатором не найдено')
        }
        contentData.value = {
          ...blankData,
          ...res,
          createdUser: !res.createdUser ? '' : `${res.createdUser.firstName || ''} ${res.createdUser.lastName}` || '',
          updatedUser: !res.updatedUser ? '' : `${res.updatedUser.firstName || ''} ${res.updatedUser.lastName}` || '',
        }
        resolve()
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  })

  const creatParams = () => {
    const {
      xmlId, active, sort, title, alias, detailText, searchableContent, showCounter, showCounterStart, metaTitle, metaKeywords, metaDescription,
    } = contentData.value
    const fields = {
      xmlId, active, sort, title, alias, detailText, searchableContent, showCounter, showCounterStart, metaTitle, metaKeywords, metaDescription,
    }

    const param = new FormData()
    Object.keys(fields).forEach(key => {
      param.append(key, fields[key])
    })
    return param
  }

  const updateElement = id => new Promise(resolve => {
    if (!ability.can('update', 'ContentHelp')) {
      store.commit('app/SET_ERROR', 'Недостаточно прав для изменения')
    }
    const param = creatParams()
    contentGeneralRepository.updateElement(id, param)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  })

  return {
    contentData,

    updateElement,
    getElement,
  }
}
